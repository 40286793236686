import { Link } from "react-router-dom"

const Explore = () => {
    return (
        <div className="explore" >
            <div className="container flex aib">
                <div className="heading flexGrow-1"> <h2>Explore Our Products</h2></div>
                <div className="imtab">
                   <Link to="/products/hybroducts">
                   <img src={process.env.PUBLIC_URL + "./assets/img/Hybroducts.jpg"} alt=""/>
                       <div className="link">Hybroducts</div>
                   </Link>
                    </div> 
                    <div className="imtab">
                   <Link to="/products/digital-transformation">
                   <img src={process.env.PUBLIC_URL + "./assets/img/Digital-Transformation.jpg"} alt=""/>
                       <div className="link">Digital Transformation</div>
                   </Link>
                    </div> 
            </div>
        </div>
    )
}

export default Explore
