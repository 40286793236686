import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"
import { Link } from "react-router-dom"
import Form from "../components/Form"
const Hybroducts = () => {
    return (
        <>
        <div className="pagebanner">
            <div className="container"> 
            <HeadingwithLine htitle="Hybroducts" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
            </div>
    <PageContent  padding="efctBg dStyle">
     <div className="row aic dt">
  <div className="col-6 col-sm-12 ">
  <h2 className="hPC">Why Do We <span>Hybridise</span> Our Products With <span>Technologies?</span></h2>
    <p>Industry 4.0 and Digitalisation has been a revolutionary process in Germany and all around the world. Our products target digital transformation to cope up with the Industrial revolution. With next generation intelligent transportation and air taxi the industry is adapting to innovative technologies. In our opinion hybrid innovation takes a prime stand to change the conservative mindset to futuristic adaptation. Especially interactive machines/products has been a key factor to determine customer satisfaction and to enhance safety feature for intelligent vehicles. With our first hybrid product FOVGRAI, we not only use an emerging material but combine the same with data and associated AI based assessment taking a technological quantum leap creating a new line of products. </p>
</div>
<div className="col-6 col-sm-12">
<img className="dtimg" src={process.env.PUBLIC_URL + "../assets/img/hp.jpg"} alt="dt"/>

  </div>
<div className="col-12">
<p><b>FOVIATECH’s</b> <i>Hybroduct strategy extends into Sensor technology, Energy harvesting and Displays</i>
</p>
<p className="staytune">
 <b><u>Stay tuned</u></b> for our next product release
</p>
</div>

  <div className="col-12">
  <h2 className="hPC"><span>FOVGRAI </span></h2>
    <p><b>FOVGRAI</b> is a smart seating interface, a system which automatically adapts the sitting postures of a user, enabling seat base and backrest self adjustable in a flexible and exact fashion depending on the user's posture enabled via nano airbag. The system enables the sensor where graphene technology is printed layer by layer to accumulate Biometric datas like Temperature and Humidity aspects of the users.</p>

    <Link to="/industry/hybroduction" className="link">Explore FOVGRAI</Link>
  </div>
     </div>
 
     <div className="row aic dt dt2">
     <div className="col-4 col-sm-12">
  <img className="dtimg" src={process.env.PUBLIC_URL + "../assets/img/hp-image.jpg"} alt="dt"/>
  </div>
  <div className="col-8 col-sm-12">
  <h2 className="hPC"><span>Features  </span></h2>
  <ul className="ulList">
  <li>Connectivity - Bluetooth or USB</li>
<li>On-device or Cloud-based computing services</li>
<li>Smartphone Android/iOS app</li>
<li>Integration with your on-chair display or app (Switch-It Remote Seating App)</li>
<li>USB interface will be supported if we integrate the sensor entirely into your system</li>
  </ul>
  </div>

     </div>
     <div className="text-center">
     <h3 className="hPC">Would Like To <span>  Explore The Value </span> Proposition?</h3>
         <p>Reach out to know more.</p>
 <Form enquiry_from="Products-Hybroducts"/>
     </div>
    </PageContent> 
    
    
    </>
    )
}

export default Hybroducts
