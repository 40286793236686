
const PageContent = (props) => {
    return (
        <section className="page">
        <div className="container">
         <div className={'pageContent ' + props.padding}>
         {props.children}
         </div>
        </div>
        </section>
    )
}
export default PageContent
