import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Clogos = () => {
    return (
        <Carousel
            additionalTransfrom={0}
            autoPlay
          
            autoPlaySpeed={2000}
            centerMode={false}
            className=""
            arrows={false}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
        
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 5,
                    partialVisibilityGutter: 30
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 2,
                    partialVisibilityGutter: 20
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 3,
                    partialVisibilityGutter: 20
                }
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={2}
            swipeable
        >
            
            <div className="clogos">
                <img src={process.env.PUBLIC_URL + "./assets/img/certx.png"} alt="Client" />
            </div>
            <div className="clogos">
                <img src={process.env.PUBLIC_URL + "./assets/img/dm.png"} alt="Client" />
            </div>
            <div className="clogos">
                <img src={process.env.PUBLIC_URL + "./assets/img/muller.png"} alt="Client" />
            </div>
    
        
        </Carousel>
    )
}

export default Clogos
