import {NavLink} from "react-router-dom";

export const Products = (props) => {
    return (
<div className="megamenu">
    <div className="backdrop">
<div>
<NavLink activeClassName="activeSubmenu" onClick={props.products1} to="/products/hybroducts">Hybroducts</NavLink>
<NavLink activeClassName="activeSubmenu" onClick={props.products2} to="/products/digital-transformation">Digital Transformation</NavLink>
</div>
</div>
</div>

    )
}


export const Industry = (props) => {
    return (
        <div className="megamenu">
        <div className="backdrop">
    <div>
    <NavLink activeClassName="activeSubmenu" onClick={props.industry1} to="/industry/hybroduction">Hybroducts</NavLink>
    <NavLink activeClassName="activeSubmenu" onClick={props.industry2} to="/industry/digital-transformation">Digital Transformation</NavLink>
    </div>
    </div>
    </div>
    
      )
}
