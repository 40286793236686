import { useState } from "react";

import { Link, NavLink } from "react-router-dom";
import { Products, Industry } from './submenu/Submenu';
function Header() {
    const [header, setHeader] = useState(false);
    const [toggle, settoggle] = useState(false);
    const toggler = () => {
        toggle ? settoggle(false) : settoggle(true);
    }
    const changebg = () => {
        if (window.scrollY > 40) {
            setHeader(true);
        }
        else {
            setHeader(false);
        }
    }
    window.addEventListener('scroll', changebg);
    return (
        <header className={header ? 'header active' : 'header'}>
            <div className="container">
                <div className="brand"><Link to="/"><img src={process.env.PUBLIC_URL + "../assets/img/logo.svg"} alt="Fovia" /></Link></div>
                <div className="navebar">
                    <button onClick={toggler} className={toggle ? "menubutton opened" : "menubutton"}>
                        <span></span><span></span><span></span>
                    </button>
                    <ul className={toggle ? "nav opened" : "nav"}>
                        <li><NavLink to="/" onClick={toggler} exact activeClassName="activelink">Home</NavLink>

                        </li>
                        <li className="submenu">Products<i className="i-angle-down"></i>
                            {/* submenu start */}
                            <Products products1={toggler} products2={toggler} />
                            {/* submenu end */}
                        </li>

                        <li className="submenu">Industry<i className="i-angle-down"></i>
                            {/* submenu start */}
                            <Industry industry1={toggler} industry2={toggler} />
                            {/* submenu end */}
                        </li>

                        <li><NavLink onClick={toggler} to="/media" exact activeClassName="activelink">Media</NavLink></li>
                        <li><NavLink onClick={toggler} to="/About" exact activeClassName="activelink">About Us</NavLink></li>
                        <li><NavLink onClick={toggler} to="/Contact-Us" exact activeClassName="activelink">Contact</NavLink></li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header
