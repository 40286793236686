import React, { Component } from 'react'
import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"
export default class NotFound extends Component {
    render() {
        return (
            
            <>
            <div className="pagebanner">
                <div className="container"> 
                <HeadingwithLine htitle="Page not found " linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
                </div>
        <PageContent padding="efctBg dStyle pdtb-100">
         <div className="row aic ">
      <div className="col-12 text-center">
          <h1 className=" text-center">404</h1>
          <p className=" text-center">Page not found !</p>
       </div>
         </div>

    
     
       </PageContent> 
        </> 
        )
    }
}
