
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiry_from: this.props.enquiry_from,
      errors: {},
      name: '',
      email: '',
      org_name: '',
      subject: '',
      message: '',
      term:0,
      isLoading: false,
      isEnquirySent:false

    }
  }

  handleValidation() {
    const { name, email, org_name, subject, message, term } = this.state;
    let errors = {};
    let formIsValid = true;

    //Name
    if (name === '') {
      formIsValid = false;
      errors["name"] = "Name is required.";
    } else if (typeof name !== "undefined") {
      if (!name.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }

    //Email
    if (email === '') {
      formIsValid = false;
      errors["email"] = "Email is required.";
    } else if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    //Name
    if (org_name === '') {
      formIsValid = false;
      errors["org_name"] = "Company / Organisation is required.";
    } else if (typeof org_name !== "undefined") {
      if (!org_name.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["org_name"] = "Only letters";
      }
    }
    if (subject === '') {
      formIsValid = false;
      errors["subject"] = "Subject is required";
    } 

    if (message === '') {
      formIsValid = false;
      errors["message"] = "Message is required.";
    } 
    if (term === 0) {
      formIsValid = false;
      errors["term"] = "Terms is required.";
    }
    
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      isLoading:true
    })
    if (this.handleValidation()) {
      const { name, email, org_name, subject, message, enquiry_from } = this.state;
       const data = JSON.stringify({
        enquiry_from:enquiry_from,
        name:name,
        email:email,
        org_name:org_name,
        subject:subject,
        message:message,
       })
       fetch('http://foviatechmail.articloo.com', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: data
      }).then(res => res.json())
        .then(res => {
          if(res.status === 'success'){
            this.setState({
              isLoading:false,
              isEnquirySent:true
            })
          }
          
        });
    }else{
      this.setState({
        isLoading:false
      })
    }

  }
  render() {
    const { errors,  isLoading, isEnquirySent } = this.state;
    return (

      <>
        {
          isEnquirySent ? <div className="responce-box">
             <h3>Thank You !</h3>
             <p>Your enquiry has been successfully sent. I will contact you sortly.</p>
             <Link to="/" className="link ">Back to Home</Link>
          </div> :  <form onSubmit={this.handleSubmit}>
        <div className="row form">
          <div className="col-6 col-sm-12">

            <input type="text" name="name" placeholder="Name*" onChange={this.handleUserInput} />
            <span className="help-error">{errors["name"]}</span>
          </div>
          <div className="col-6 col-sm-12">
            <input type="text" name="org_name" placeholder="Company / Organisation*" onChange={this.handleUserInput} />
            <span className="help-error">{errors["org_name"]}</span>
          </div>
          <div className="col-6 col-sm-12">
            <input type="email" name="email" placeholder="Email*" onChange={this.handleUserInput} />
            <span className="help-error">{errors["email"]}</span>
          </div>
          <div className="col-6 col-sm-12">
            <input type="text" name="subject" placeholder="Subject*" onChange={this.handleUserInput} />
            <span className="help-error">{errors["subject"]}</span>
          </div>
          <div className="col-12">
            <textarea name="message" placeholder="Message*" rows={6} onChange={this.handleUserInput} />
            <span className="help-error">{errors["message"]}</span>
          </div>
          <div className="col-12">

            <div className="flex aic agree">
              <input type="checkbox" id="term" name="term" value="1" onChange={this.handleUserInput} />
              <label for="term" > I hearby give FOVIATECH consent to contact me regarding my queries<span>*</span></label>
              
            </div>
            <span className="help-error">{errors["term"]}</span>
          </div>
          <div className="col-12">
            <button type="submit" className="link submit mtop20"> { isLoading ? "Please wait.." : "Submit" }</button>
          </div>
        </div>
      </form>
        }
      </>
     

    )
  }
}
