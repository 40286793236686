
import Thumb from "../components/blogComponent/Thumb"

const News = () => {
    return (
     <>
      <Thumb goTo="/Blog-Details" />
      <Thumb goTo="/Blog-Details" />
      <Thumb goTo="/Blog-Details" />
      <Thumb goTo="/Blog-Details" />
     </>
    )
}

export default News
