import { Link } from "react-router-dom"
import TextLoop from "react-text-loop";
import {useState} from 'react'
const Banner = () => {
    const [maskstate] = useState(true)
    return (
        <div className="banner">
            <div className="bgEfect"/>
            <div className="contentpart">
    <div className="container">
        <div className="bannerContent">
            <div className="contet">
                <TextLoop mask={maskstate} springConfig={{ stiffness: 150, damping: 10, mask:false, }}>
                    <div>
                    <h1>Welcome To <br />Foviatech</h1>
                        <p>Join Our Mission and Evolve your </p>
                       <Link to="/industry/digital-transformation" className="link">Overview</Link>
                    </div>
                    <div>
                        <h1>Digital<br/> Transformation and<br/> Smart Automation</h1>
                        <p>Join Our Mission and Evolve your </p>
                       <Link to="/products/digital-transformation" className="link">Overview</Link>
                    </div>
                </TextLoop>
            </div>
        </div>
        <div className="bannerimage">
<img src={process.env.PUBLIC_URL + "./assets/img/chipset.svg"} alt="chipsets" />
        </div>
    </div>
    </div>
        </div>
    )
}

export default Banner
