import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Thumb from "../blogComponent/Thumb"
const Media = () => {
    return (
        <Carousel
            additionalTransfrom={0}
            autoPlay={false}
          
            autoPlaySpeed={2000}
            centerMode={false}
            className="medislider"
            arrows={true}
            dotListClass=""
            draggable
            focusOnSelect={false}
           
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
        
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 4,
                    partialVisibilityGutter: 30
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 20
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 20
                }
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={2}
            swipeable
        >
            
            <Thumb crslThumb="homeNewThumv" thumbnailImg={process.env.PUBLIC_URL + "./assets/img/thumbg.jpg"} goTo="/Blog-Details" />
            <Thumb crslThumb="homeNewThumv" goTo="/Blog-Details" />
            <Thumb crslThumb="homeNewThumv" thumbnailImg={process.env.PUBLIC_URL + "./assets/img/thumbg.jpg"} goTo="/Blog-Details" />
            <Thumb crslThumb="homeNewThumv" goTo="/Blog-Details" />
            <Thumb crslThumb="homeNewThumv" thumbnailImg={process.env.PUBLIC_URL + "./assets/img/thumbg.jpg"} goTo="/Blog-Details" />
            <Thumb crslThumb="homeNewThumv" goTo="/Blog-Details" />
            <Thumb crslThumb="homeNewThumv" thumbnailImg={process.env.PUBLIC_URL + "./assets/img/thumbg.jpg"} goTo="/Blog-Details" />
        
        </Carousel>
    )
}

export default Media
