import { Link } from "react-router-dom"


const BlogSmallThumbnails = (props) => {
    return (
        <div className="smallthumbnails">
        <img src={props.icons} alt="coverImage" />
        <div className="bound">
            <h3>{props.heading}</h3>
            <p>{props.para}</p>
            <div className="readMore"> <Link to={props.goTo}><span>Read More</span></Link></div>
          
        </div>
        </div>
    )
}

BlogSmallThumbnails.defaultProps = {
    icons: process.env.PUBLIC_URL + "./assets/img/males.jpg",
    heading: "What is Lorem Ipsum ?",
    para:"Lorem Ipsum is simply dummy text of the printing",
    goTo:"/",
  };

export default BlogSmallThumbnails
