import Header from '../src/assets/components/Header';
import Footer from '../src/assets/components/Footer';
import Home from '../src/assets/pages/Home';
import { Switch, Route} from "react-router-dom";
import ContactUs from './assets/pages/ContactUs';
import BlogDetails from './assets/pages/BlogDetails';   
import ScrollToTop from "./assets/components/ScrollToTop";
import DigitalTransformation from './assets/pages/DigitalTransformation';
import Hybroducts from './assets/pages/Hybroducts';
import IndustryDigitalTransformation from './assets/pages/IndustryDigitalTransformation';
import IndustryHybroduction from './assets/pages/IndustryHybroduction';
import About from './assets/pages/About';
import NewsLayout from './assets/components/Layout/NewsLayout';
import Impressum from './assets/pages/Impressum';
import PrivacyPolicy from './assets/pages/PrivacyPolicy';
import TermsAndCondition from './assets/pages/TermsAndCondition';
import NotFound from './assets/pages/NotFound';

function App() {
  return (
<>
<Header/>
<ScrollToTop/>
<Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/products/digital-transformation" component={DigitalTransformation}/>
        <Route path="/products/hybroducts" component={Hybroducts}/>
        <Route path="/industry/digital-transformation" component={IndustryDigitalTransformation}/>
        <Route path="/industry/hybroduction" component={IndustryHybroduction}/>
        <Route path="/Contact-Us" component={ContactUs}/> 
        <Route path="/media" component={NewsLayout}/>
        <Route path="/Blog-Details" component={BlogDetails}/>
        <Route path="/About" component={About}/>
        <Route path="/impressum" component={Impressum}/>
        <Route path="/privacy-policy" component={PrivacyPolicy}/>
        <Route path="/terms-and condition" component={TermsAndCondition}/>
        <Route component={NotFound}/>
        
      </Switch>
    <Footer/>
</>
  );
}

export default App;
