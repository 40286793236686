import React, { Component } from 'react'
import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"
import { Link } from 'react-router-dom'
export class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errors: {},
            fname: '',
            lname: '',
            org_name: '',
            email: '',
            mobile: '',
            reason: '',
            subject: '',
            message: '',
            term: 0,
            isEnquirySent: false,
        }
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }


    handleValidation() {
        const { fname, org_name, email, reason, subject, message, term } = this.state;
        let errors = {};
        let formIsValid = true;

        //Name
        if (fname === '') {
            formIsValid = false;
            errors["fname"] = "First name is required.";
        } else if (typeof fname !== "undefined") {
            if (!fname.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["fname"] = "Only letters";
            }
        }

        //Email
        if (email === '') {
            formIsValid = false;
            errors["email"] = "Email is required.";
        } else if (typeof email !== "undefined") {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }
        //Name
        if (org_name === '') {
            formIsValid = false;
            errors["org_name"] = "Organisation is required.";
        } else if (typeof org_name !== "undefined") {
            if (!org_name.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["org_name"] = "Only letters";
            }
        }

        if (reason === '') {
            formIsValid = false;
            errors["reason"] = "Reason is required.";
        }


        if (subject === '') {
            formIsValid = false;
            errors["subject"] = "Subject is required";
        }

        if (message === '') {
            formIsValid = false;
            errors["message"] = "Message is required.";
        }

        // if (mobile.length !== 10) {
        //     formIsValid = false;
        //     errors["mobile"] = "Invalid mobile number.";
        // }
        if (term === 0) {
            formIsValid = false;
            errors["term"] = "Terms is required.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }



    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({
                isLoading: true,
            })
            const { fname, lname, org_name, email, mobile, reason, subject, message } = this.state;
            const data = JSON.stringify({
                name: fname + " " + lname,
                org_name: org_name,
                email: email,
                mobile: mobile,
                reason: reason,
                subject: subject,
                message: message,
            })
            fetch('http://foviatechmail.articloo.com/send-contact.php', {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: data
            }).then(res => res.json())
                .then(res => {
                    if (res.status === 'success') {
                        this.setState({
                            isLoading: false,
                            isEnquirySent: true
                        })
                    }

                });

        }

    }
    render() {
        const { errors, isEnquirySent, isLoading } = this.state;
        return (
            <>
                <div className="pagebanner">
                    <div className="container">
                        <HeadingwithLine htitle="Contact Us" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
                </div>
                <PageContent padding="efctBg dStyle conatct-page">
                    <div className="row">
                        <div className="col-6 col-xs-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4740.730728867097!2d10.024721!3d53.551245!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xba41cea0445d6a71!2sFoviatech%20GmbH!5e0!3m2!1sen!2sde!4v1626245393260!5m2!1sen!2sde" style={{ border: 0, width: '100%', height: '520px' }} title="map" allowFullScreen loading="lazy" />
                        </div>

                        <div className="col-6 col-xs-12 form">

                            {
                                isEnquirySent ?
                                    <div className="responce-box">
                                        <h3>Thank You !</h3>
                                        <p className="text-center">Your enquiry has been successfully sent. I will contact you sortly.</p>
                                        <Link to="/" className="link ">Back to Home</Link>
                                    </div>
                                    : <>
                                        <h2 ><span>Q</span>uick <span>E</span>nquiry</h2>
                                        <form className="row" onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-6 col-md-12">
                                                    <input type="text" name="fname" placeholder="First Name*" onChange={this.handleUserInput} />
                                                    <span className="help-error">{errors["fname"]}</span>
                                                </div>
                                                <div className="col-6 col-md-12">
                                                    <input type="text" name="lname" placeholder="Last Name" onChange={this.handleUserInput} />
                                                </div>

                                                <div className="col-12">
                                                    <input type="text" name="org_name" placeholder="Organization*" onChange={this.handleUserInput} />
                                                    <span className="help-error">{errors["org_name"]}</span>
                                                </div>
                                                <div className="col-6 col-md-12">
                                                    <input type="email" name="email" placeholder="Email*" onChange={this.handleUserInput} />
                                                    <span className="help-error">{errors["email"]}</span>
                                                </div>
                                                <div className="col-6 col-md-12">
                                                    <input type="number" name="mobile" placeholder="Mobile" onChange={this.handleUserInput} />
                                                    <span className="help-error">{errors["mobile"]}</span>
                                                    
                                                </div>
                                                <div className="col-12">
                                                    <select name="reason" onChange={this.handleUserInput}>
                                                        <option selected value="Other">Other</option>
                                                        <option value="Ask about products (partners and clients)">Ask about products (partners and clients)</option>
                                                        <option value="Join our team (career aspirants)">Join our team (career aspirants)</option>
                                                       
                                                    </select>
                                                    <span className="help-error">{errors["reason"]}</span>
                                                </div>
                                                <div className="col-12">
                                                    <input type="text" name="subject" placeholder="Subject *" onChange={this.handleUserInput} />
                                                    <span className="help-error">{errors["subject"]}</span>
                                                </div>
                                                <div className="col-12">
                                                    <textarea name="message" placeholder="Message*" rows={5} onChange={this.handleUserInput} />
                                                    <span className="help-error">{errors["message"]}</span>
                                                </div>
                                                <div className="col-12">
                                                    <div className="flex aic agree">
                                                        <input type="checkbox" id="term" name="mail" value="" />
                                                        <label htmlFor="term"> "Stay Connected" &#128712; Join our Newsletter to receive news and updates.</label>
                                                    </div>
                                                    <div className="flex aic agree">
                                                        <input type="checkbox" id="agree" name="term" value="1" onChange={this.handleUserInput} />
                                                        <label htmlFor="agree" > I hearby give FOVIATECH consent to contact me regarding my queries<span>*</span></label>
                                                    </div>
                                                    <span className="help-error">{errors["term"]}</span>
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit" className="link submit">{ isLoading ? "Please wait.." : "Submit" }</button>
                                                </div>
                                            </div>
                                        </form>

                                    </>
                            }



                        </div>
                    </div>

                    <div className="row multiple pdt-35">
                        <div className="col-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="Contactde flex">
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16.4"
                                        height="22.7"
                                        x="0"
                                        y="0"
                                        enableBackground="new 0 0 16.4 22.7"
                                        viewBox="0 0 16.4 22.7"
                                    >
                                        <path
                                            fill="#272F6A"
                                            d="M8.2 0C3.7 0 0 3.7 0 8.2c0 5.6 7.4 13.9 7.7 14.2.3.3.8.3 1.1 0 .3-.3 7.7-8.6 7.7-14.2C16.4 3.7 12.8 0 8.2 0zm0 12.4c-2.3 0-4.1-1.9-4.1-4.1S6 4.2 8.2 4.2s4.1 1.9 4.1 4.1c.1 2.2-1.8 4.1-4.1 4.1z"
                                        ></path>
                                    </svg>
                                </i>
                                <div>
                                    <h2><span>H</span>eadquarter: </h2>
                                    <p>Foviatech GmbH
                                        Spaldingstraße 210
                                        20097 Hamburg
                                        Germany</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="Contactde flex">  <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.4"
                                    height="22.7"
                                    x="0"
                                    y="0"
                                    enableBackground="new 0 0 16.4 22.7"
                                    viewBox="0 0 16.4 22.7"
                                >
                                    <path
                                        fill="#272F6A"
                                        d="M8.2 0C3.7 0 0 3.7 0 8.2c0 5.6 7.4 13.9 7.7 14.2.3.3.8.3 1.1 0 .3-.3 7.7-8.6 7.7-14.2C16.4 3.7 12.8 0 8.2 0zm0 12.4c-2.3 0-4.1-1.9-4.1-4.1S6 4.2 8.2 4.2s4.1 1.9 4.1 4.1c.1 2.2-1.8 4.1-4.1 4.1z"
                                    ></path>
                                </svg>
                            </i>
                                <div>
                                    <h2><span>L</span>ocation:</h2>
                                    <p><b className="i-angle-right-f" /> Chennai, India</p>
                                    <p><b className="i-angle-right-f" /> UAE, Midle East</p>
                                    <p><b className="i-angle-right-f" /> Sweden, Scandinavia</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="Contactde flex">
                                <i className="i-email" />
                                <div>
                                    <h2><span>E</span>mail:</h2>
                                    <p>info@foviatech.com</p>
                                    <p>admin@foviatech</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="Contactde flex">
                                <i className="i-call" />
                                <div>
                                    <h2><span>C</span>all:</h2>
                                    <p>+49 40 8836 6160</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageContent>
            </>
        )
    }
}

export default ContactUs
