import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"
import Clogos from "../components/homeComponents/Clogos"

const About = () => {
    return (
        <>
        <div className="pagebanner">
            <div className="container"> 
            <HeadingwithLine htitle="About Us" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
            </div>
    <PageContent padding="efctBg dStyle about">
     <div className="row aic dt">
  <div className="col-12">
    <p>
    <span>Foviatech</span> uses digitalisation and smart automation strategies to transform the transportation and healthcare industries. We support automation and digitisation with artificial intelligence, end-to-end learning and deployment services. <span>Foviatech</span> offers AI based customised software solutions adaptable to Production and MRO processes for transportation industries.
    </p>
    <p>
    <span>Foviatech's</span> innovative strategy of combining artificial intelligence and graphene occupies a special position for industrialisation and digitisation. Foviatech has a unique in-depth expertise in both 2D Material technology, AI and their combination. Software and materials scientists work together in an interdisciplinary team to develop innovative solutions for the Transportation and Health care. One example of the hybrid products are sensor interfaces for seats, which enables digitalisation through Ergonomics instruction, Biometric data provision and Comfort enhancement. Our novel product, <span>FOVGRAI</span> SmartSeat, a Graphene-based flexible sensor coupled with AI technology, enables to self-figure the seats based on user position directly contributing comfort automation.
</p>
</div>
     </div>
<div className="dt25 text-center">
     <h2 className="hPC"><span>Team</span></h2>
     <div className="row cc multiple text-center">
         <div className="col-4 col-sm-6 col-xs-12">
            <div className="team">
                <img className="imgss" src={process.env.PUBLIC_URL + "./assets/img/team/st.jpg"} alt="chipsets" />
                <h2> 
                Sowmya Thyagarajan
                </h2>
                <p>CO-FOUNDER & CEO/GESCHÄFTSFÜHRERIN</p>
                </div> 
         </div>
         <div className="col-4 col-sm-6 col-xs-12">
            <div className="team">
                <img className="imgss" src={process.env.PUBLIC_URL + "./assets/img/team/cb1.jpg"} alt="chipsets" />
                <h2> 
                <span>Dr.</span> Chandrakant Bothe
                </h2>
                <p>CO-FOUNDER & CHIEF AI TECH OFFICER</p>
                </div> 
         </div>
         <div className="col-4 col-sm-6 col-xs-12">
            <div className="team">
                <img className="imgss" src={process.env.PUBLIC_URL + "./assets/img/team/pl.jpg"} alt="chipsets" />
                <h2> 
                <span>Dr.</span> Per Löthman
                </h2>
                <p>SENIOR MATERIAL SCIENTIST</p>
                </div> 
         </div>
         <div className="col-4 col-sm-6 col-xs-12">
            <div className="team">
                <img className="imgss" src={process.env.PUBLIC_URL + "./assets/img/team/lc.jpg"} alt="chipsets" />
                <h2> 
                Leigh A. Hooper
                </h2>
                <p>CHIEF FINANCIAL OFFICER</p>
                </div> 
         </div>
         <div className="col-4 col-sm-6 col-xs-12">
            <div className="team">
                <img className="imgss" src={process.env.PUBLIC_URL + "./assets/img/team/jr.jpg"} alt="chipsets" />
                <h2> 
                Jörg Reitmann
                </h2>
                <p>AVIATION CONSULTANT</p>
                </div> 
         </div>

     </div>

     </div>
     <h2 className="hPC text-center"><span>Clients</span></h2>
  <Clogos/>
   </PageContent> 
    </> 
    )
}

export default About
