import React, { Component } from 'react'

import Form from "../components/Form"
import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"
export default class DigitalTransformation extends Component {
  constructor() {
    super();

    this.fullDesc = React.createRef()
}
  scrollTofullDesc = () => window.scroll({
    top: this.fullDesc.current.offsetTop,
    left: 0,
    behavior: 'smooth'
  });
  render() {
    return (
      <>
      <div className="pagebanner">
          <div className="container"> 
          <HeadingwithLine htitle="Digital Transformation" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
          </div>
  <PageContent padding="efctBg dStyle">
   <div className="row aic dt">
<div className="col-6 col-sm-12">
<h2 className="hPC"><span>FOVIAR</span></h2>
  <p>Customisable and collaborative products with features adaptable to your shops and production centres. </p>
<h3 className="hPC">Explore <span>Digital Transformation</span> with FOVIAR</h3>
<p>Dynamically combines artificial intelligence and augmented reality to enable interactive detection features. A digital visualisation of objects is generated through an interface between the real world and computer simulated environment. Product <b>FOVIAR</b> completely works on visual perspective and with AI backend automating SRM measurements and provide appropriate instructions to maintenance workers.</p>

<button className="link" type="button" onClick={() => this.scrollTofullDesc()}>Let‘s build together</button>
</div>
<div className="col-6 col-sm-12">

<video width="100%" poster={process.env.PUBLIC_URL + "../assets/img/poster2.jpg"} controls>
        <source src="https://www.foviatech.com/videos/Foviatech-Foviar-Maintanance-UseCase.MP4" type="video/mp4" />
      </video>
</div>
   </div>

   <div className="row aic dt dt2">
   <div className="col-4 col-sm-12">
<img className="dtimg" src={process.env.PUBLIC_URL + "../assets/img/image.png"} alt="dt"/>
</div>
<div className="col-8 col-sm-12">
<h2 className="hPC"><span>Features </span></h2>
<p>1. Hybrid combination of AI and Augmented reality.</p>

<p>2. AI enhanced engineering validation at the back end.</p>

<p>3. SRM (Structural Repair Manual ) and Engineering manual automation.</p>

</div>

   </div>
   <div className="text-center">
       <h3 className="hPC">Would Like To <span>  Explore The Value </span> Proposition?</h3>
       <p ref={this.fullDesc}>Reach out to know more.</p>
 <Form enquiry_from="Products-DigitalTransformation"/>
   </div>
  </PageContent> 
  
  
  </>
    )
  }
}
