import { Link } from "react-router-dom"

const DigitalTransformation = () => {
    return (
<section className="section-130 digitalTransformation">
    <div className="container">
        <div className="flex">
<div>
    <Link to="/">
<div className="circles">
 <img src={process.env.PUBLIC_URL + "./assets/img/foviatech-automotive.jpg"} alt="foviatech-automotive" />
 <div><span>Automotive</span></div>
</div>
</Link>
</div>

<div>
<Link to="/">
<div className="circles">
 <img src={process.env.PUBLIC_URL + "./assets/img/foviatech-aviation.jpg"} alt="foviatech-aviation" />
 <div><span>Aviation</span></div>
</div>
</Link>
</div>
<div className="circles center">
 <img src={process.env.PUBLIC_URL + "./assets/img/foviatech-automotive.jpg"} alt="foviatech-automotive" />
 <div>
 <span>
     Digital
   <b>Transformation</b>
   </span>
 </div>
</div>
<div>
<Link to="/">
<div className="circles">
 <img src={process.env.PUBLIC_URL + "./assets/img/foviatech-marine.jpg"} alt="foviatech-marine" />
 <div><span>Marine</span></div>
</div>
</Link>
</div>
<div>
<Link to="/">
<div className="circles">
 <img src={process.env.PUBLIC_URL + "./assets/img/foviatech-locomotive.jpg"} alt="foviatech-automotive" />
 <div><span>Locomotive</span></div>
</div>
</Link>
</div>
        </div>
    </div>
</section>
    )
}

export default DigitalTransformation
