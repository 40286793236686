import HeadingwithLine from "../HeadingwithLine"
import Media from "./Media"

const NewSection = () => {
    return (
        <section className="section blueBg">
          <div className="container">
              <HeadingwithLine htitle="News" headingClass="sectionTitle white" linecolor="flexGrow-1 line white"/>
              <Media/>
              </div>  
        </section>
    )
}

export default NewSection
