import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"

const Impressum = () => {
    return (
        <>
        <div className="pagebanner">
            <div className="container"> 
            <HeadingwithLine htitle="Impressum" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
            </div>
    <PageContent  padding="efctBg dStyle">
     <div className="row aic dt">
  <div className="col-12 ">

    <p>Imprint according to § 5 TMG (German Act for Telemedia Services) and § 55 Rundfunkstaatsvertrag <br/>
(German Interstate Treaty on Broadcasting).</p>
<p>Foviatech GmbH<br/>
Spaldingstraße 210<br/>
20097 Hamburg<br/>
Germany<br/><br/>
Email: info@foviatech.com<br/>
www: http://www.foviatech.com</p>
<p>Attorney of Foviatech GmbH (Gesellschaft mit beschränkter Haftung) is managing director Sowmya Thyagarajan.<br/><br/>

Registration court and registration number of Foviatech GmbH: District court of Hamburg, HRB 151494.<br/><br/>

Value added tax identification number: DE318095100<br/><br/>

Responsible in the sense of § 55, 2nd paragraph of Rundfunkstaatsvertrag (German Interstate Treaty on Broadcasting)
Sowmya Thyagarajan, Spaldingstraße 210 , 20097 Hamburg, Germany<br/><br/>

This imprint also applies to services of Foviatech GmbH on Facebook, Twitter, LinkedIn and YouTube.</p>
</div>
<h2 className="hPC"><span>Liability</span></h2>
<p>Foviatech cannot be held liable either for mistakes in editorial or technical aspects, nor for omissions, nor for the correctness of the content. In particular, Foviatech does not guarantee completeness or correctness of information contained in external websites which can be accessed via links from Foviatech's websites. Despite accurate research on the content of such linked external websites, Foviatech cannot be held liable for their content. Only the content providers of such external sites are liable for their content. Should you notice any mistake in technical or editorial aspects of the Foviatech site, please do not hesitate to inform us.</p>

<h2 className="hPC">Personal <span>Data Privacy</span></h2>
<p>By accessing the Foviatech website or using other Foviatech online services such as mobile applications, certain information about users, such as internet protocol (IP) addresses, record of navigation through the site, software used and time spent, as well as similar information, will be stored on Foviatech servers. This information will not specifically identify users and the information will be used internally for website traffic analysis only. If users provide unique identifying information, such as name, address or other information on forms stored on this site, this information will be used for statistical purposes only and will not be published or made available for general access. Foviatech does not sell nor rent its website users' names, addresses, email addresses or other personal information.</p>


     </div>
 

    </PageContent> 
    </>
    )
}

export default Impressum
