import { Link } from "react-router-dom"

const Thumb = (props) => {
    return (
        <div className={props.crslThumb}>
        <div className="thumb flex aic">
            <div className="thumb-img"><img src={props.thumbnailImg} alt="blog" /></div>
    
      <div>
      <h3>{props.heading}</h3>
        <p>{props.para}</p>
        <div className="readMore"> <Link to={props.goTo}><span>Read More</span></Link></div>
      </div>
    
        </div>
    </div>
    )
}


Thumb.defaultProps = {
  crslThumb:"col-6 col-md-12",
    thumbnailImg: process.env.PUBLIC_URL + "./assets/img/male.jpg",
    heading: "What is Lorem Ipsum ?",
    para:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    goTo:"/",
  };

export default Thumb
