
import Thumb from "../components/blogComponent/Thumb"

const Inside = () => {
    return (
     <>
   <Thumb goTo="/Blog-Details" />
   <Thumb goTo="/Blog-Details" />
   <Thumb goTo="/Blog-Details" />
   <Thumb goTo="/Blog-Details" />
   <Thumb goTo="/Blog-Details" />
   <Thumb goTo="/Blog-Details" />
     </>
    )
}

export default Inside
