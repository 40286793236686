import Form from "../components/Form"
import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"


const IndustryHybroduction = () => {
    return (
        <>
        <div className="pagebanner">
            <div className="container"> 
            <HeadingwithLine htitle="Industry" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
            </div>
    <PageContent  padding="efctBg dStyle">
    <h2 className="hPC text-center"><span>Hybroducts</span> </h2>
    <br/><br/>
     <div className="row aic dt">
  <div className="col-12 col-sm-12  ">

  <video width="100%" controls poster={process.env.PUBLIC_URL + "../assets/img/poster3.jpg"}>
        <source src="https://www.foviatech.com/videos/Foviatech-Fovgrai-SmartSeat.mp4" type="video/mp4" />
      </video>
</div>


     </div>
 
     
     <div className="text-center">
         <h3 className="hPC">Explore <span>FOVGRAI</span> Product In <span>Aviation And Health</span> Care Sector</h3>
      
     <Form enquiry_from="Industry-Hybroduction"/>
       </div>
    </PageContent> 
    
    
    </>
    )
}

export default IndustryHybroduction
