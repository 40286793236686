import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-4 col-md-4 col-sm-12 Flogo">
                        <img src={process.env.PUBLIC_URL + "../assets/img/logo-2.svg"} alt="footer logo" />
                        <p> <Link to="/terms-and condition"> Terms And Conditions</Link></p>
                        <p> <Link to="/privacy-policy"> Privacy Policy</Link></p>
                        <p> <Link to="/impressum"> Impressum </Link></p>

                    </div>
                    <div className="col-3 col-md-4 col-sm-12 Flink">
                        <h3>Company</h3>
                        <span className="Flink">
                            <Link to="/media">News</Link>
                            <Link to="/Contact-Us">Contact</Link>
                            {/* <Link to="/">Career</Link> */}
                            </span>

                    </div>
                    <div className="col-3 col-md-4 col-sm-12  Flink">
                        <h3>Services</h3>
                        <span className="Flink">
                            <Link to="/Hybroducts">Products</Link>
                            <Link to="/IndustryHybroduction">Industry</Link>

                        </span> 

                    </div>
                    <div className="col-2 col-md-12 social-icon">

                        <a href="https://www.linkedin.com/company/foviatech" rel="noreferrer" target="_blank"><i className="i-linkedin"></i></a>
                     
                        <a href="https://www.xing.com/pages/foviatechgmbh" rel="noreferrer" target="_blank"><i className="i-xing2"></i></a>
                    </div>
                </div>
                <div className="copyright">
                    Copyright ©2021 Foviatech All rights reserved
                </div>
            </div>
        </footer>
    )
}

export default Footer
