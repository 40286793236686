import { Link } from "react-router-dom"
import HeadingwithLine from "../HeadingwithLine"

const HomeAbout = () => {
    return (
        <section className="section">
            <div className="container">
                <HeadingwithLine />
                <div className="row aboutHome">
                    <div className=" col-4 col-md-6 col-sm-4 col-xs-12 image">
                        <img src={process.env.PUBLIC_URL + "./assets/img/aboutUs.jpg"} alt="about us"></img>
                    </div>
                    <div className="contents col-8 col-md-6 col-sm-8 col-xs-12">
                        <p>
                            <span>Foviatech</span> uses digitalisation and smart automation strategies to transform the transportation and healthcare industries. We support automation and digitisation with artificial intelligence, end-to-end learning and deployment services. <span>Foviatech</span> offers AI based customised software solutions adaptable to Production and MRO processes for transportation industries.
                        </p>
                        <p>
                            <span>Foviatech's</span> innovative strategy of combining artificial intelligence and graphene occupies a special position for industrialisation and digitisation. Foviatech has a unique in-depth expertise in both 2D Material technology, AI and their combination. Software and materials scientists work together in an interdisciplinary team to develop innovative solutions for the Transportation and Health care. One example of the hybrid products are sensor interfaces for seats, which enables digitalisation through Ergonomics instruction, Biometric data provision and Comfort enhancement.
                        </p>
                        <div className="readmoreBar">
                            <Link to="/About" className="link">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default HomeAbout
