import React, { Component } from 'react'
import HeadingwithLine from "../../components/HeadingwithLine"
import PageContent from "../../components/PageContent"
import { Switch, Link, Route } from "react-router-dom"

import News from '../../pages/News'
import Inside from '../../pages/Inside'
export default class NewsLayout extends Component {
    render() {
        return (
            <>
                <div className="pagebanner2">
        <img src={process.env.PUBLIC_URL + "./assets/img/media.jpg"} alt="coverImage" />
       <span className="b-overlay">
     <div className="container">
     <HeadingwithLine htitle="Media" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" />
     </div>
       </span> </div>


                <PageContent padding="efctBg">
                    <div className="flex tabss">
                        <Link to="/media/news" className="link" activeClassName='active'>News/Press Release </Link>
                        <Link to="/media/inside" activeClassName='active' className="link">Blogs & Insights </Link>
                    </div>
                    <div className="row multiple">
                        <Switch>
                             <Route path="/media" component={News}/>
                             <Route path="/media/news" component={News}/>
                             <Route path="/media/inside" component={Inside}/>
                        </Switch>
                    </div>
                </PageContent>
            </>
        )
    }
}
