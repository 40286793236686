import Form from "../components/Form"
import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"

const IndustryDigitalTransformation = () => {
    return (
        <>
        <div className="pagebanner">
            <div className="container"> 
            <HeadingwithLine htitle="Industry" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
            </div>
    <PageContent  padding="efctBg dStyle">
    <h2 className="hPC">Digital Transformation</h2>
     <div className="row aic dt">
  <div className="col-6 col-sm-12 ">

  <h2 className="hPC"><span>MRO</span></h2>
<ul className="ulList">
<li>Ground support processes</li>
<li>Inspections on various check </li>
<li>Maintenance & Repair assistance</li>
<li>Machine operations with added functions like augmenting Machine drawings, Maintenance work order, Repair manual instructions, Engineering validation.</li>
</ul>

</div>
  <div className="col-6 col-sm-12 ">


<video width="100%" poster={process.env.PUBLIC_URL + "../assets/img/poster.jpg"} controls>
        <source src="https://www.foviatech.com/videos/Foviatech-Foviar-Engine-UseCase.mp4" type="video/mp4" />
      </video>

  {/* <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/H45vSzyiXH4?start=10" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
  </div>

     </div>
 
     <div className="row aic dt dt2">
     <div className="col-4 col-sm-12">
  <img className="dtimg" src={process.env.PUBLIC_URL + "../assets/img/image.png"} alt="dt"/>
  </div>
  <div className="col-8 col-sm-12 ">
  <h2 className="hPC"><span>Production</span> </h2>
  <ul className="ulList">
  <li>Production processes, Quality checks </li>
<li>Machine operations with added functions like augmenting Construction manuals, Machine drawings.</li>
  </ul>


  </div>

     </div>
     <div className="text-center">
         <h3 className="hPC">Explore <span>FOVIAR</span> Implementation in <span>Production</span> Centers and Final Assembly Lines </h3>
      
 <Form enquiry_from="Industry-DigitalTransformation"/>
     </div>
    </PageContent> 
    
    
    </>
    )
}

export default IndustryDigitalTransformation
