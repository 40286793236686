import Clogos from "./Clogos"

const Clients = () => {
    return (
        <section className="section offWhite">
            <div className="container">
                <Clogos/>
            </div>
        </section>
    )
}

export default Clients
