import { Link } from "react-router-dom"

const Industry = () => {
    return (
<section className="industry">
<div className="iheading">
    <h2 className="sectionTitle">Industry</h2>
</div>
<div className="icontent">
    <Link to="/industry/hybroduction">
    <img src={process.env.PUBLIC_URL + "./assets/img/healthCare.jpg"} alt="healthCare"/>
    <div>
        <span>Health Care</span>
    </div>
    </Link>
</div>
<div className="icontent">
    <Link to="/industry/hybroduction">
    <img src={process.env.PUBLIC_URL + "./assets/img/transportation.jpg"} alt="transportation"/>
    <div>
        <span>Transportation</span>
    </div>
    </Link>
</div>
<div className="icontent"></div>
</section>
    )
}

export default Industry
