import Banner from "../components/Banner"
import Clients from "../components/homeComponents/Clients"
import DigitalTransformation from "../components/homeComponents/DigitalTransformation"
import Explore from "../components/homeComponents/Explore"
import HomeAbout from "../components/homeComponents/HomeAbout"
import Industry from "../components/homeComponents/Industry"
import NewSection from "../components/homeComponents/NewSection"

const Home = () => {
    return (
      <>
 <Banner/>
 <Explore/>
 <HomeAbout/>
 <DigitalTransformation/>
 <Industry/>
 <NewSection/>
 <Clients/>
      </>
    )
}

export default Home
